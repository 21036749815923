import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from '../baseQuery';

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: baseQuery,
  tagTypes: ['categories','categoriesById'],
  endpoints: (builder) => ({
    getCategories :builder.query({
        query:()=>`/categories`,
        providesTags:['categories']
    }),
    getCategoriesById:builder.query({
        query:()=>'/categories/user-categories',
        providesTags:['categoriesById']
    }),
    toggleCategory:builder.mutation({
      query:(interest)=>({
          url:`users/toggle-interests`,
          method:'POST',
          body:{ newInterest: interest },
      }),
      invalidatesTags:['categories','categoriesById']
  }),
  })
})

export const {useGetCategoriesQuery,useGetCategoriesByIdQuery,useToggleCategoryMutation} = categoryApi 