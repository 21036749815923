import { authMiddleware } from '@/RTK/middleware/authMiddleware';
import { globalMiddleware } from '@/RTK/middleware/globalMiddleware';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './slice/auth/authSlice';
import counterReducer from './slice/counterSlice';
import articleReducer from "./slice/article/articleSlice"
import { useDispatch, useSelector } from 'react-redux';
import { authApi } from '@/RTK/services/authApi';
import { s3Api } from '@/RTK/services/s3Api';
import { articlesApi } from '@/RTK/services/articleApi';
import {userApi} from "@/RTK/services/userApi"
import {categoryApi} from '@/RTK/services/categoryApi'
const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: [], // Do not blacklist any auth state keys
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  article:articleReducer,
  counter:persistReducer({key:"counter",storage},counterReducer),

  // RTK Query
  [authApi.reducerPath]: authApi.reducer,
  [s3Api.reducerPath]:s3Api.reducer,
  [userApi.reducerPath]:userApi.reducer,
  [articlesApi.reducerPath]:articlesApi.reducer,
  [categoryApi.reducerPath] : categoryApi.reducer
});

const makeConfiguredStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(authApi.middleware,s3Api.middleware, articlesApi.middleware,authMiddleware, userApi.middleware,categoryApi.middleware, globalMiddleware),
  });

export const makeStore = () => {
  const isServer = typeof window === 'undefined';
  if (isServer) {
    return makeConfiguredStore();
  } else {
    let store = configureStore({
      reducer: rootReducer,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        }).concat(authApi.middleware, s3Api.middleware,articlesApi.middleware,authMiddleware, userApi.middleware, categoryApi.middleware,globalMiddleware),
    });
    store.__persistor = persistStore(store);
    return store;
  }
};

