import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";

export const s3Api = createApi({
  reducerPath: "s3Api",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getPresignedUrl: builder.mutation({
      query: (contentType) => ({
        url: `/s3/presigned-url`,
        method: "POST",
        body: { contentType },
      }),
    }),
    updateFile: builder.mutation({
      query: (key, contentType) => ({
        url: `/s3/update/${key}`,
        method: "POST",
        body: { contentType },
      }),
    }),
    deleteFile: builder.mutation({
      query: (key) => ({
        url: `/s3/delete?key=${key}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetPresignedUrlMutation,
  useUpdateFileMutation,
  useDeleteFileMutation,
} = s3Api;
