import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from '../baseQuery';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/signin',
        method: 'POST',
        body: credentials,
      }),
    }),

    register: builder.mutation({
      query: (credentials) => ({
        url: '/auth/signup',
        method: 'POST',
        body: credentials,
      }),
    }),

    forgotPassword: builder.mutation({
      query: (credentials) => ({
        url: '/auth/forgot-password',
        method: 'POST',
        body: credentials,
      }),
    }),
    verifyEmail:builder.mutation({
      query:(emailToken)=>`/auth/verify-email?email-token=${emailToken}`
    }),
    validateToken: builder.query({
      query: (token) => `/auth/validate-forgot-password-code/${token}`,
    }),

    validateOTP: builder.query({
      query: (otp) => `/auth/validate-otp/${otp}`,
    }),

    resetPassword: builder.mutation({
      query: (data) => ({
        url: '/auth/reset-password',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation,useForgotPasswordMutation,useResetPasswordMutation,useValidateOTPQuery,useValidateTokenQuery ,useVerifyEmailMutation} = authApi;
